@media (max-width: 500px) {
    main {
        max-width: 100vw;
    }
}

@media (min-width: 1024px) {
    .main-app {
        min-height: calc(100vh - 435px);
        margin-top: 140px;
        max-width: 1440px;
        width: 97%;
    }
    .main-app-cms {
        margin-top: 0px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-desktop {
        display: none !important;
    }
    .header-middle__search .MuiFormControl-marginNormal {
        margin: 0;
    }
    .main-app {
        min-height: calc(100vh - 435px);
        margin-top: 140px;
    }
    .main-app-cms {
        margin-top: 0px;
    }
}

@media (min-width: 1920) {
    .hidden-xl {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-mobile {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) and (max-width: 1919px) {
    .hidden-lg {
        display: none !important;
    }
}
